var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recruitment"},[(_vm.fullWidth < 767)?_c('div',[_c('Banner',{attrs:{"bannerimg":_vm.staticUrl +
          (_vm.topBanner['cover_mobile_' + _vm.$i18n.locale]
            ? _vm.topBanner['cover_mobile_' + _vm.$i18n.locale]
            : _vm.topBanner.cover_mobile)}},[_c('p',{domProps:{"innerHTML":_vm._s(
          (_vm.topBanner['description_mobile_' + _vm.$i18n.locale]
            ? _vm.topBanner['description_mobile_' + _vm.$i18n.locale]
            : _vm.topBanner.description_mobile) == ''
            ? _vm.topBanner['description_' + _vm.$i18n.locale]
              ? _vm.topBanner['description_' + _vm.$i18n.locale]
              : _vm.topBanner.description
            : _vm.topBanner['description_mobile_' + _vm.$i18n.locale]
            ? _vm.topBanner['description_mobile_' + _vm.$i18n.locale]
            : _vm.topBanner.description_mobile
        )}})])],1):_c('div',[_c('Banner',{attrs:{"bannerimg":_vm.staticUrl +
          (_vm.topBanner['cover_' + _vm.$i18n.locale]
            ? _vm.topBanner['cover_' + _vm.$i18n.locale]
            : _vm.topBanner.cover)}},[_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.topBanner['description_' + _vm.$i18n.locale]
            ? _vm.topBanner['description_' + _vm.$i18n.locale]
            : _vm.topBanner.description
        )}})])],1),_c('div',{staticClass:"recruitment-wrap container"},[_c('Standard'),_c('Organization')],1),_c('job-page')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }