<template>
  <div class="recruitment">
    <div v-if="fullWidth < 767">
      <Banner
        :bannerimg="
          staticUrl +
            (topBanner['cover_mobile_' + $i18n.locale]
              ? topBanner['cover_mobile_' + $i18n.locale]
              : topBanner.cover_mobile)
        "
      >
        <p
          v-html="
            (topBanner['description_mobile_' + $i18n.locale]
              ? topBanner['description_mobile_' + $i18n.locale]
              : topBanner.description_mobile) == ''
              ? topBanner['description_' + $i18n.locale]
                ? topBanner['description_' + $i18n.locale]
                : topBanner.description
              : topBanner['description_mobile_' + $i18n.locale]
              ? topBanner['description_mobile_' + $i18n.locale]
              : topBanner.description_mobile
          "
        ></p>
      </Banner>
    </div>
    <div v-else>
      <Banner
        :bannerimg="
          staticUrl +
            (topBanner['cover_' + $i18n.locale]
              ? topBanner['cover_' + $i18n.locale]
              : topBanner.cover)
        "
      >
        <p
          v-html="
            topBanner['description_' + $i18n.locale]
              ? topBanner['description_' + $i18n.locale]
              : topBanner.description
          "
        ></p>
      </Banner>
    </div>
    <div class="recruitment-wrap container">
      <!-- standard -->
      <Standard></Standard>
      <!-- organization -->
      <Organization></Organization>
    </div>
    <!-- job -->
    <job-page></job-page>
  </div>
</template>

<script>
import Banner from "../../components/Banner";
import JobPage from "../Introduction/JobPage.vue";
import Standard from "./Standard.vue";
import Organization from "./Organization.vue";
import request from "../../api/request";
export default {
  name: "recruitment",
  components: {
    Banner,
    JobPage,
    Standard,
    Organization,
  },
  data() {
    return {
      topBanner: {},
      staticUrl: request.staticUrl,
      fullWidth: 0,
    };
  },
  mounted() {
    this.banners();
    this.handleResize();
  },
  methods: {
    banners() {
      let _this = this;
      request
        .get("/banners", {
          banner_type__in: "8",
        })
        .then(function(res) {
          if (res.status == 200) {
            _this.topBanner = res.data.results[0];
          }
        });
    },
    handleResize(event) {
      this.fullWidth = document.documentElement.clientWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
