<template>
  <div class="organization">
    <div class="organization-title">
      {{ $t("조직도") }}
    </div>
    <div class="orgImg">
      <img
        :src="
          banner['cover_' + $i18n.locale]
            ? banner['cover_' + $i18n.locale]
            : banner.cover
        "
      />
    </div>
    <!-- <vue2-org-tree
      :data="data"
      :horizontal="horizontal"
      :collapsable="collapsable"
      :label-class-name="labelClassName"
      :render-content="renderContent"
      selected-class-name="bg-tomato"
      selected-key="selectedKey"
      @on-expand="onExpand"
      @on-node-click="onNodeClick"
    /> -->
  </div>
</template>

<script>
import request from "../../api/request";

export default {
  components: {},
  data() {
    return {
      banner: {
        cover: require("../../assets/img/img_img02_recruit.jpg"),
      },
      data: {
        id: 0,
        label: "XXX科技有限公司",
        children: [
          {
            id: 1,
            label: "222",
          },
          {
            id: 2,
            label: "产品研发部",
            children: [
              {
                id: 7,
                label: "研发-前端",
              },
            ],
          },
          {
            id: 3,
            label: "销售部",
            children: [
              {
                id: 7,
                label: "销售一部",
              },
              {
                id: 8,
                label: "销售二部",
              },
              {
                id: 9,
                label: "销售三部",
              },
            ],
          },
          {
            id: 4,
            label: "财务部",
            children: [
              {
                id: 7,
                label: "销售一部",
              },
              {
                id: 8,
                label: "销售二部",
              },
            ],
          },
          {
            id: 5,
            label: "财务部",
            children: [
              {
                id: 7,
                label: "销售一部",
              },
              {
                id: 8,
                label: "销售二部",
              },
            ],
          },
          {
            id: 6,
            label: "HR人事",
            children: [
              {
                id: 9,
                label: "销售一部",
              },
              {
                id: 10,
                label: "销售二部",
              },
            ],
          },
        ],
      },
      expandAll: false,
      horizontal: false,
      collapsable: false,
    };
  },
  methods: {
    banners() {
      let _this = this;
      request
        .get("/banners", {
          banner_type__in: "12",
        })
        .then(function(res) {
          if (res.status == 200) {
            _this.banner = res.data.results[0];
          }
        });
    },
    labelClassName: function(data) {
      return "clickable-node";
    },
    renderContent: function(h, data) {
      // return data.label
      // 颜色数组
      let colors = [
        "blue",
        "#000",
        "#ccc",
        "#ccc",
        "#ccc",
        "#ccc",
        "#ccc",
        "#fff",
        "#fff",
        "#fff",
        "#fff",
        "#fff",
        "#fff",
        "#fff",
        "#fff",
        "#fff",
        "#fff",
      ];
      let fontcolor = [
        "fff",
        "#fff",
        "#fff",
        "#fff",
        "#fff",
        "#fff",
        "#fff",
        "#969696",
        "#969696",
        "#969696",
        "#969696",
        "#969696",
        "#969696",
        "#969696",
        "#969696",
        "#969696",
        "#969696",
      ];
      return h(
        "div",
        {
          style: {
            margin: "-10px -15px",
            padding: "10px 15px",
            backgroundColor: colors[data.id % colors.length],
            color: fontcolor[data.id % fontcolor.length],
          },
        },
        data.label
      );
    },
    onExpand: function(e, data) {
      if ("expand" in data) {
        data.expand = !data.expand;

        if (!data.expand && data.children) {
          this.collapse(data.children);
        }
      } else {
        this.$set(data, "expand", true);
      }
    },
    onNodeClick: function(e, data) {
      console.log("onNodeClick: %o", data);
      this.$set(data, "selectedKey", !data.selectedKey);
    },
    collapse: function(list) {
      var _this = this;
      list.forEach(function(child) {
        if (child.expand) {
          child.expand = false;
        }

        child.children && _this.collapse(child.children);
      });
    },
    expandChange: function() {
      this.toggleExpand(this.data, this.expandAll);
    },
    toggleExpand: function(data, val) {
      var _this = this;
      if (Array.isArray(data)) {
        data.forEach(function(item) {
          _this.$set(item, "expand", val);
          if (item.children) {
            _this.toggleExpand(item.children, val);
          }
        });
      } else {
        this.$set(data, "expand", val);
        if (data.children) {
          _this.toggleExpand(data.children, val);
        }
      }
    },
  },
  mounted() {
    this.banners();
  },
};
</script>

<style>
.organization {
  margin: 100px 0;
  text-align: center;
}
.organization .organization-title {
  color: #000;
  font-weight: bolder;
  font-size: 20px;
}
.org-tree-node-label {
  white-space: nowrap;
}
.clickable-node {
  cursor: pointer;
}
.clickable-node {
  color: #fff;
}
.organization .orgImg {
  width: 100%;
}
.organization .orgImg img {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .organization {
    margin: 20px 0;
    text-align: center;
  }
}
</style>
