<template>
  <div class="standard">
    <img
      :src="
        banner['cover_' + $i18n.locale]
          ? banner['cover_' + $i18n.locale]
          : banner.cover
      "
    />
  </div>
</template>

<script>
import request from "../../api/request";

export default {
  components: {},
  data() {
    return {
      banner: {
        cover: require("../../assets/img/img_img01_recruit.jpg"),
      },
    };
  },
  methods: {
    banners() {
      let _this = this;
      request
        .get("/banners", {
          banner_type__in: "11",
        })
        .then(function(res) {
          if (res.status == 200) {
            _this.banner = res.data.results[0];
          }
        });
    },
  },
  mounted() {
    this.banners();
  },
};
</script>

<style>
.standard {
  width: 80%;
  margin: 0px auto;
  padding-top: 60px;
}
.standard img {
  width: 100%;
}
</style>
